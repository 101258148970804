import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectCreateNewProject,
  selectSelectedGroup,
  selectSelectedProject,
  selectProjectsInGroup,
  selectNewProjectName,
  setCreateNewProject,
  setSelectedGroup,
  setSelectedProject,
  setProjectsInGroup,
  setNewProjectName,
} from 'app/sharedSlice';
// import { Switch } from '@headlessui/react';
import ComboboxAutocomplete from './ComboboxAutocomplete';
import InformationTooltip from 'components/InformationTooltip';
import type { IGroup, IProject } from 'utils/types';
import { getConfig } from 'config/config-helper';
import { classNames } from 'utils/styleUtils';

const { dataopsliveBaseUrl } = getConfig();

export const sanitizeProjectName = (name: string): string => {
  const sanitizedProjectName = name.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9_\-.+\s]/g, '');
  return sanitizedProjectName;
};

interface GroupAndProjectSelectorProps {
  showCreateNewProject?: boolean;
  showProjectSelector?: boolean;
  projectSelectorDisabled?: boolean;
  onProjectSelectionChanged?: () => void;
}

const GroupAndProjectSelector = (props: GroupAndProjectSelectorProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const createNewProject = useAppSelector(selectCreateNewProject);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const selectedProject = useAppSelector(selectSelectedProject);
  const projectsInGroup = useAppSelector(selectProjectsInGroup);
  const newProjectName = useAppSelector(selectNewProjectName);

  const [groups, setGroups] = useState<IGroup[]>([]);
  const [groupSearchTerm, setGroupSearchTerm] = useState('');
  const [projectSearchTerm, setProjectSearchTerm] = useState('');

  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  useEffect(() => {
    if (selectedGroup === null || selectedGroup.name === '') {
      return;
    }

    axios
      .get(
        `${dataopsliveBaseUrl}/api/v4/groups/${selectedGroup.id}/projects?min_access_level=30&search=${projectSearchTerm}&order_by=similarity`,
        {
          withCredentials: false,
        },
      )
      .then((response) => {
        console.log(response.data);
        dispatch(
          setProjectsInGroup(
            response.data.map((project: any) => ({
              name: project.name,
              id: project.id,
              path: project.path_with_namespace,
            })),
          ),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedGroup, projectSearchTerm]);

  useEffect(() => {
    axios
      .get(`${dataopsliveBaseUrl}/api/v4/groups?min_access_level=30&search=${groupSearchTerm}&order_by=similarity`, {
        withCredentials: false,
      })
      .then((response) => {
        console.log(response.data);
        setGroups(
          response.data.map((group: any) => ({
            name: group.name,
            id: group.id,
            path: group.full_path,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [groupSearchTerm]);

  useEffect(() => {
    if (groups.length === 1) {
      dispatch(setSelectedGroup(groups[0]));
    }
  }, [groups]);

  useEffect(() => {
    if (projectsInGroup.length === 1) {
      dispatch(setSelectedProject(projectsInGroup[0]));
    }
  }, [projectsInGroup]);

  return (
    <>
      {props.showCreateNewProject !== false && (
        //   <Field as="div" className="col-span-2 flex items-center justify-center">
        //     <Switch
        //       checked={createNewProject}
        //       onChange={() => {
        //         dispatch(setCreateNewProject(!createNewProject));
        //         if (props.onProjectSelectionChanged) {
        //           props.onProjectSelectionChanged();
        //         }
        //       }}
        //       className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
        //     >
        //       <span className="sr-only">Use setting</span>
        //       <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
        //       <span
        //         aria-hidden="true"
        //         className={classNames(
        //           createNewProject ? 'bg-dataops-primary-blue' : 'bg-gray-200',
        //           'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
        //         )}
        //       />
        //       <span
        //         aria-hidden="true"
        //         className={classNames(
        //           createNewProject ? 'translate-x-5' : 'translate-x-0',
        //           'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
        //         )}
        //       />
        //     </Switch>
        //     <Label as="span" className="ml-1 cursor-default flex">
        //       <span className="text-sm font-medium text-gray-700">Create new data product</span>
        //       <InformationTooltip tooltip="Select an existing data product or create a new one" />
        //     </Label>
        //   </Field>
        <div className="col-span-2">
          <div className="w-fit mx-[auto] flex space-x-1 rounded-lg bg-slate-100 p-0.5">
            <button
              className={classNames(
                createNewProject ? 'bg-white shadow' : '',
                'flex flex-col items-center rounded-md py-1 pl-2 pr-2 text-sm font-semibold lg:pr-3',
              )}
              type="button"
              onClick={() => dispatch(setCreateNewProject(true))}
            >
              <span className="w-[7rem] text-sm lg:ml-2 text-gray-800 font-medium">New</span>
              <span className="w-[7rem] text-sm lg:ml-2 text-gray-800 font-medium">Data Product</span>
            </button>
            <button
              className={classNames(
                createNewProject ? '' : 'bg-white shadow',
                'flex flex-col items-center rounded-md py-1 pl-2 pr-2 text-sm font-semibold lg:pr-3',
              )}
              type="button"
              onClick={() => dispatch(setCreateNewProject(false))}
            >
              <span className="w-[7rem] text-sm lg:ml-2 text-gray-800 font-medium">Existing</span>
              <span className="w-[7rem] text-sm lg:ml-2 text-gray-800 font-medium">Data Product</span>
            </button>
          </div>
        </div>
      )}

      <div className="col-span-2 xl:col-span-1">
        <ComboboxAutocomplete
          label="DataOps group"
          options={groups}
          selected={selectedGroup}
          setSelected={(group: IGroup) => dispatch(setSelectedGroup(group))}
          query={groupSearchTerm}
          setQuery={setGroupSearchTerm}
          tooltip="Select the DataOps group to create the data product within"
        />
      </div>

      {props.showProjectSelector !== false && (
        <>
          {createNewProject && (
            <div className="col-span-2 xl:col-span-1">
              <label
                htmlFor="new-project-name"
                className="flex items-center text-sm font-medium leading-6 text-gray-900"
              >
                <div>New Data Product</div>
                <InformationTooltip tooltip="Enter a name for the DataOps project to create the data product within" />
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={newProjectName}
                    onChange={(e) => dispatch(setNewProjectName(sanitizeProjectName(e.target.value)))}
                    name="new-project-name"
                    id="new-project-name"
                    autoComplete="new-project-name"
                    className="block rounded-md flex-1 ring-1 ring-inset ring-gray-300 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                    required
                    disabled={props.projectSelectorDisabled === true}
                  />
                </div>
              </div>
            </div>
          )}

          {!createNewProject && (
            <div className="col-span-2 xl:col-span-1">
              <ComboboxAutocomplete
                label="Data Product"
                options={projectsInGroup}
                selected={selectedProject}
                setSelected={(project: IProject) => {
                  dispatch(setSelectedProject(project));
                  if (props.onProjectSelectionChanged) {
                    props.onProjectSelectionChanged();
                  }
                }}
                query={projectSearchTerm}
                setQuery={setProjectSearchTerm}
                tooltip="Select the DataOps project to create the data product within"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GroupAndProjectSelector;
