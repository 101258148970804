import React, { useState, useEffect } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import {
  useAddSnowflakeAccountMutation,
  useAddSnowflakeAccountPKeyMutation,
  useGetOauthIntegrationsQuery,
  useGetSnowflakeAccountsQuery,
} from 'app/createApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectSnowflakeAccount,
  setSelectedDatabase,
  selectSnowflakeAuthType,
  selectSnowflakeIsMFA,
  selectPersistSnowflakeAccount,
  setSnowflakeAuthType,
  setSnowflakeAccount,
  setSnowflakeAccountLabel,
  setSnowflakeAccountConnected,
  setSnowflakeRole,
  setSnowflakeWarehouse,
  setSnowflakeIsMFA,
} from 'app/snowflakeSlice';
import { clearSelectedObjects } from '../features/data-product-builder/reducers/builderSlice';
import { Switch, Field, Label } from '@headlessui/react';
import PersistSnowflakeAccountButton from './PersistSnowflakeAccountButton';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import InformationTooltip from './InformationTooltip';
import { classNames } from 'utils/styleUtils';
import AccountFormButton from './AccountFormButton';
import SnowflakeOauthAccountDialog from './SnowflakeOauthAccountDialog';

function parseAccountFromURL(url: string): string {
  if (url.endsWith('.privatelink')) {
    return url;
  }

  const start = 'https://';
  const end = '.snowflakecomputing.com';
  const endWithSlash = '.snowflakecomputing.com/';
  const splitParts = url.split('.');
  if (url.startsWith(start) && url.endsWith(end)) {
    const account = url.substring(start.length, url.length - end.length);
    return account;
  } else if (url.startsWith(start) && url.endsWith(endWithSlash)) {
    const account = url.substring(start.length, url.length - endWithSlash.length);
    return account;
  } else if (splitParts.length === 2) {
    const account = splitParts.join('-');
    return account;
  }
  return url;
}

export interface IOauthIntegration {
  account: string;
  client_id: string;
  client_secret: string;
  id: number;
  is_editable: boolean;
  token_url: string;
  warehouse: string;
  role: string;
  is_shared_with_group: boolean;
}

interface SnowflakeAccountFormProps {
  snowflakeAccount: string;
  setSnowflakeAccount: (account: string) => void;
  snowflakeUsername: string;
  setSnowflakeUsername: (username: string) => void;
  snowflakeWarehouse: string;
  setSnowflakeWarehouse: (warehouse: string) => void;
  snowflakeRole: string;
  setSnowflakeRole: (role: string) => void;
  snowflakeAccountConnected: boolean;
  setSnowflakeAccountConnected: (connected: boolean) => void;
  snowflakePrivateKey: string;
  setSnowflakePrivateKey: (privateKey: string) => void;
  snowflakePassphrase: string;
  setSnowflakePassphrase: (passphrase: string) => void;
}

export default function SnowflakeAccountForm(props: SnowflakeAccountFormProps): JSX.Element {
  const dispatch = useAppDispatch();

  const deploymentEnv = process.env.REACT_APP_ENV;

  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const snowflakeAuthType = useAppSelector(selectSnowflakeAuthType);
  const snowflakeIsMFA = useAppSelector(selectSnowflakeIsMFA);
  const persistSnowflakeAccount = useAppSelector(selectPersistSnowflakeAccount);

  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("Couldn't connect to Snowflake");
  const [trimAccount, setTrimAccount] = useState(deploymentEnv !== 'az');

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [accountToDelete, setAccountToDelete] = useState<string>('');

  const [addSnowflakeAccount] = useAddSnowflakeAccountMutation();
  const [addSnowflakeAccountPKey] = useAddSnowflakeAccountPKeyMutation();

  const { data: snowflakeAccounts } = useGetSnowflakeAccountsQuery({});
  const [hasPersistedSnowflakeAccounts, setHasPersistedSnowflakeAccounts] = useState<boolean>(false);
  const { data: oauthIntegrations } = useGetOauthIntegrationsQuery([]);

  const [openAuthenticateDialog, setOpenAuthenticateDialog] = useState<boolean>(false);
  const [integrationIndex, setIntegrationIndex] = useState<number>(-999);

  const [tabs, setTabs] = useState(['Username-Password', 'Key-Pair']);

  useEffect(() => {
    let tabs = ['Username-Password', 'Key-Pair'];
    if (oauthIntegrations !== undefined && oauthIntegrations.length > 0) {
      tabs = ['Username-Password', 'Key-Pair', 'OAuth'];
    }
    if (hasPersistedSnowflakeAccounts) {
      setTabs(['Use Existing', 'Username-Password', 'Key-Pair', 'OAuth']);
      dispatch(setSnowflakeAuthType('persisted'));
      return;
    }
    setTabs(tabs);
  }, [hasPersistedSnowflakeAccounts]);

  useEffect(() => {
    if (snowflakeAccounts !== undefined && Object.keys(snowflakeAccounts).length > 0) {
      setHasPersistedSnowflakeAccounts(true);
    }
  }, [snowflakeAccounts]);

  useEffect(() => {
    if (!hasPersistedSnowflakeAccounts && snowflakeAuthType === 'persisted') {
      dispatch(setSnowflakeAuthType('password'));
    }
  }, [hasPersistedSnowflakeAccounts, snowflakeAuthType]);

  useEffect(() => {
    if (hasPersistedSnowflakeAccounts && Object.keys(snowflakeAccounts).length === 1) {
      const onlyKey = Object.keys(snowflakeAccounts)[0];
      dispatch(setSnowflakeAccountLabel(snowflakeAccounts[onlyKey].account));
      dispatch(setSnowflakeAccount(onlyKey));
      dispatch(setSnowflakeRole(snowflakeAccounts[onlyKey].role));
      dispatch(setSnowflakeWarehouse(snowflakeAccounts[onlyKey].warehouse));
      dispatch(setSnowflakeAccountConnected(true));
    }
  }, [hasPersistedSnowflakeAccounts, snowflakeAccounts]);

  useEffect(() => {
    if (trimAccount) {
      const parsedAccount = parseAccountFromURL(props.snowflakeAccount);
      props.setSnowflakeAccount(parsedAccount);
    }
  }, [trimAccount]);

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    props.setSnowflakeAccountConnected(false);
    setFailed(false);
    setLoading(true);
    if (snowflakeAuthType === 'password') {
      addSnowflakeAccount({
        account: props.snowflakeAccount,
        username: props.snowflakeUsername,
        password,
        warehouse: props.snowflakeWarehouse,
        role: props.snowflakeRole,
        isMFA: snowflakeIsMFA,
        persist: persistSnowflakeAccount,
      })
        .unwrap()
        .then((res: any) => {
          console.log(res);
          props.setSnowflakeAccountConnected(true);
          dispatch(setSnowflakeAccount(res));
          setFailed(false);
          dispatch(setSelectedDatabase(''));
          dispatch(clearSelectedObjects());
        })
        .catch((err) => {
          console.log(err);
          props.setSnowflakeAccountConnected(false);
          if (err.data !== undefined) setErrorMessage(err.data);
          else setErrorMessage("Couldn't connect to Snowflake");
          setFailed(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      addSnowflakeAccountPKey({
        account: props.snowflakeAccount,
        username: props.snowflakeUsername,
        warehouse: props.snowflakeWarehouse,
        role: props.snowflakeRole,
        privateKey: props.snowflakePrivateKey,
        privateKeyPwd: props.snowflakePassphrase,
        persist: persistSnowflakeAccount,
      })
        .unwrap()
        .then((res: any) => {
          console.log(res);
          props.setSnowflakeAccountConnected(true);
          dispatch(setSnowflakeAccount(res));
          setFailed(false);
          dispatch(setSelectedDatabase(''));
          dispatch(clearSelectedObjects());
        })
        .catch((err) => {
          console.log(err);
          props.setSnowflakeAccountConnected(false);
          if (err.data !== undefined) setErrorMessage(err.data);
          else setErrorMessage("Couldn't connect to Snowflake");
          setFailed(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="w-[22rem]">
        <div className="border-b border-gray-200 mb-6">
          <nav aria-label="Tabs" className="-mb-px flex justify-between">
            {tabs.map((tab) => (
              <div
                key={tab}
                onClick={() =>
                  dispatch(
                    setSnowflakeAuthType(
                      tab === 'Username-Password'
                        ? 'password'
                        : tab === 'Key-Pair'
                        ? 'key-pair'
                        : tab === 'OAuth'
                        ? 'oauth'
                        : 'persisted',
                    ),
                  )
                }
                className={classNames(
                  (tab === 'Username-Password' && snowflakeAuthType === 'password') ||
                    (tab === 'Key-Pair' && snowflakeAuthType === 'key-pair') ||
                    (tab === 'Use Existing' && snowflakeAuthType === 'persisted') ||
                    (tab === 'OAuth' && snowflakeAuthType === 'oauth')
                    ? 'border-dataops-secondary-blue text-dataops-secondary-blue'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'flex-1 whitespace-nowrap border-b-2 px-1 py-2 text-center text-sm font-medium cursor-pointer',
                )}
              >
                {tab}
              </div>
            ))}
          </nav>
        </div>
      </div>

      {snowflakeAuthType === 'password' && (
        <form className="space-y-4 flex flex-col items-center" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm w-fit">
            <div className="relative">
              <Field as="div" className="flex items-center justify-center ml-[auto] mb-1">
                <Switch
                  checked={trimAccount}
                  onChange={() => setTrimAccount(!trimAccount)}
                  className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                >
                  <span className="sr-only">Use setting</span>
                  <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                  <span
                    aria-hidden="true"
                    className={classNames(
                      trimAccount ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                      'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                    )}
                  />
                  <span
                    aria-hidden="true"
                    className={classNames(
                      trimAccount ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Label as="span" className="ml-1 cursor-default flex items-center">
                  <span className="text-sm font-medium text-gray-700">Trim account</span>
                  <InformationTooltip tooltip="If turned on, the Snowflake account URL you paste in the Account field is automatically cleaned and formatted. Turn off the toggle if you are unhappy with the result." />
                </Label>
              </Field>
              <div className="absolute top-0 right-0">
                <PersistSnowflakeAccountButton />
              </div>
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Account
              </span>
              <input
                value={props.snowflakeAccount}
                name="account"
                id="account-field"
                onChange={(e) => props.setSnowflakeAccount(e.target.value)}
                onPaste={(e) => {
                  e.preventDefault();
                  const pastedText = e.clipboardData.getData('text/plain');
                  const parsedAccount = trimAccount ? parseAccountFromURL(pastedText) : pastedText;
                  props.setSnowflakeAccount(parsedAccount);
                }}
                placeholder="zdxupxd-training"
                required
                className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
              />
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Username
              </span>
              <input
                value={props.snowflakeUsername}
                name="username"
                id="username-field"
                onChange={(e) => props.setSnowflakeUsername(e.target.value)}
                placeholder="dataops_obs"
                required
                className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
              />
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Password
              </span>
              <input
                type="password"
                name="password"
                id="password-field"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Your password"
                required
                className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
              />
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Warehouse
              </span>
              <input
                value={props.snowflakeWarehouse}
                name="warehouse"
                id="warehouse-field"
                onChange={(e) => props.setSnowflakeWarehouse(e.target.value)}
                placeholder="DATAOPS_OBS_WH"
                required
                className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
              />
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Role
              </span>
              <input
                value={props.snowflakeRole}
                name="role"
                id="role-field"
                onChange={(e) => props.setSnowflakeRole(e.target.value)}
                placeholder="DATAOPS_OBS_VIEWER"
                className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
              />
            </div>
          </div>
          <Field as="div" className="flex items-center justify-center mb-1">
            <Switch
              checked={snowflakeIsMFA}
              onChange={() => dispatch(setSnowflakeIsMFA(!snowflakeIsMFA))}
              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
            >
              <span className="sr-only">Use setting</span>
              <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
              <span
                aria-hidden="true"
                className={classNames(
                  snowflakeIsMFA ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                  'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                )}
              />
              <span
                aria-hidden="true"
                className={classNames(
                  snowflakeIsMFA ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                )}
              />
            </Switch>
            <Label as="span" className="ml-1 cursor-default flex items-center">
              <span className="text-sm font-medium text-gray-700">MFA</span>
              <InformationTooltip tooltip="Toggle if your Snowflake account has MFA enabled" />
            </Label>
          </Field>
          {loading && !failed && (
            <div className="min-h-6 flex items-center justify-center">
              <div
                className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <button
            type="submit"
            className="w-[22rem] bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300"
          >
            Connect
          </button>
          <div className="w-[22rem]">
            {failed && (
              <div className="min-h-6 w-full flex items-center justify-center">
                <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                  <a className="w-full text-center font-medium text-red-600 hover:text-red-500 break-words p-1">
                    {errorMessage}
                  </a>
                </div>
              </div>
            )}
            {props.snowflakeAccountConnected && (
              <div className="min-h-6 w-full flex items-center justify-center">
                <div className="text-sm w-full rounded bg-green-50 border-2 border-green-500 flex items-center justify-center">
                  <a className="font-medium text-green-600 hover:text-green-500">Account added</a>
                </div>
              </div>
            )}
          </div>
        </form>
      )}

      {snowflakeAuthType === 'key-pair' && (
        <form className="space-y-4 flex flex-col items-center" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm w-fit">
            <div className="relative">
              <Field as="div" className="flex items-center justify-center ml-[auto] mb-1">
                <Switch
                  checked={trimAccount}
                  onChange={() => setTrimAccount(!trimAccount)}
                  className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                >
                  <span className="sr-only">Use setting</span>
                  <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                  <span
                    aria-hidden="true"
                    className={classNames(
                      trimAccount ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                      'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                    )}
                  />
                  <span
                    aria-hidden="true"
                    className={classNames(
                      trimAccount ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Label as="span" className="ml-1 cursor-default flex items-center">
                  <span className="text-sm font-medium text-gray-700">Trim account</span>
                  <InformationTooltip tooltip="If turned on, the Snowflake account URL you paste in the Account field is automatically cleaned and formatted. Turn off the toggle if you are unhappy with the result." />
                </Label>
              </Field>
              <div className="absolute top-0 right-0">
                <PersistSnowflakeAccountButton />
              </div>
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Account
              </span>
              <input
                value={props.snowflakeAccount}
                name="account"
                id="account-field"
                onChange={(e) => props.setSnowflakeAccount(e.target.value)}
                onPaste={(e) => {
                  e.preventDefault();
                  const pastedText = e.clipboardData.getData('text/plain');
                  const parsedAccount = trimAccount ? parseAccountFromURL(pastedText) : pastedText;
                  props.setSnowflakeAccount(parsedAccount);
                }}
                placeholder="zdxupxd-training"
                required
                className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
              />
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Username
              </span>
              <input
                value={props.snowflakeUsername}
                name="username"
                id="username-field"
                onChange={(e) => props.setSnowflakeUsername(e.target.value)}
                placeholder="dataops_obs"
                required
                className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
              />
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Private Key
              </span>
              <textarea
                rows={1}
                name="privatekey"
                id="privatekey-field"
                value={props.snowflakePrivateKey}
                onChange={(e) => props.setSnowflakePrivateKey(e.target.value)}
                placeholder="Paste your private key here"
                required
                className={classNames(
                  props.snowflakePrivateKey === '' ? '' : 'blurred-text',
                  'relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm',
                )}
              />
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Passphrase
              </span>
              <input
                type="password"
                name="passphrase"
                id="passphrase-field"
                value={props.snowflakePassphrase}
                onChange={(e) => props.setSnowflakePassphrase(e.target.value)}
                placeholder="Passphrase for private key"
                className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
              />
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Warehouse
              </span>
              <input
                value={props.snowflakeWarehouse}
                name="warehouse"
                id="warehouse-field"
                onChange={(e) => props.setSnowflakeWarehouse(e.target.value)}
                placeholder="DATAOPS_OBS_WH"
                required
                className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
              />
            </div>
            <div className="flex rounded-md shadow-sm">
              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Role
              </span>
              <input
                value={props.snowflakeRole}
                name="role"
                id="role-field"
                onChange={(e) => props.setSnowflakeRole(e.target.value)}
                placeholder="DATAOPS_OBS_VIEWER"
                className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
              />
            </div>
          </div>
          {loading && !failed && (
            <div className="min-h-6 flex items-center justify-center">
              <div
                className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <button
            type="submit"
            className="w-[22rem] bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300"
          >
            Connect
          </button>
          <div className="w-[22rem]">
            {failed && (
              <div className="min-h-6 w-full flex items-center justify-center">
                <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                  <a className="w-full text-center font-medium text-red-600 hover:text-red-500 break-words p-1">
                    {errorMessage}
                  </a>
                </div>
              </div>
            )}
            {props.snowflakeAccountConnected && (
              <div className="min-h-6 w-full flex items-center justify-center">
                <div className="text-sm w-full rounded bg-green-50 border-2 border-green-500 flex items-center justify-center">
                  <a className="font-medium text-green-600 hover:text-green-500">Account added</a>
                </div>
              </div>
            )}
          </div>
        </form>
      )}

      {snowflakeAuthType === 'persisted' && (
        <>
          <ul className="w-[22rem] flex flex-col items-center space-y-2">
            {snowflakeAccounts !== undefined &&
              Object.keys(snowflakeAccounts ?? []).map((account: string) => (
                <li
                  key={account}
                  className={classNames(
                    'w-4/5 overflow-hidden rounded-md border cursor-pointer shadow-sm',
                    snowflakeAccount === account ? 'border-gray-400' : 'border-gray-200',
                  )}
                  onClick={() => {
                    if (snowflakeAccount === account) {
                      dispatch(setSnowflakeAccount(''));
                      dispatch(setSnowflakeRole(''));
                      dispatch(setSnowflakeWarehouse(''));
                      dispatch(setSnowflakeAccountConnected(false));
                    } else {
                      dispatch(setSnowflakeAccountLabel(snowflakeAccounts[account as any].account));
                      dispatch(setSnowflakeAccount(account));
                      dispatch(setSnowflakeRole(snowflakeAccounts[account as any].role));
                      dispatch(setSnowflakeWarehouse(snowflakeAccounts[account as any].warehouse));
                      dispatch(setSnowflakeAccountConnected(true));
                    }
                  }}
                >
                  <div className="w-full px-2 flex items-center border-b border-gray-900/5 bg-gray-50 py-1">
                    <input
                      checked={snowflakeAccount === account}
                      onClick={() => {
                        if (snowflakeAccount === account) {
                          dispatch(setSnowflakeAccount(''));
                          dispatch(setSnowflakeAccountConnected(false));
                        } else {
                          dispatch(setSnowflakeAccount(account));
                          dispatch(setSnowflakeAccountConnected(true));
                        }
                      }}
                      id={account}
                      name="snowflake-account"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-dataops-secondary-blue focus:ring-dataops-secondary-blue cursor-pointer"
                    />
                    <div className="ml-2 text-gray-700">{snowflakeAccounts[account as any].account}</div>
                    <TrashIcon
                      className="h-5 w-5 text-red-600 hover:text-red-500 ml-[auto]"
                      onClick={() => {
                        setAccountToDelete(account);
                        setOpenDeleteConfirmation(true);
                      }}
                    />
                  </div>
                  <dl className="divide-y divide-gray-100 text-sm max-w-full px-2">
                    <div className="flex gap-x-4 py-1">
                      <dt className="min-w-[4.5rem] text-gray-500">Username</dt>
                      <dd className="text-gray-700 truncate">{snowflakeAccounts[account as any].username}</dd>
                    </div>
                    <div className="flex gap-x-4 py-1">
                      <dt className="min-w-[4.5rem] text-gray-500">Role</dt>
                      <dd className="text-gray-700 truncate">{snowflakeAccounts[account as any].role}</dd>
                    </div>
                    <div className="flex gap-x-4 py-1">
                      <dt className="min-w-[4.5rem] text-gray-500">Type</dt>
                      <dd className="text-gray-700 truncate">{snowflakeAccounts[account as any].type}</dd>
                    </div>
                  </dl>
                </li>
              ))}
          </ul>
          {snowflakeAccounts !== undefined && Object.keys(snowflakeAccounts).length === 0 && (
            <div className="text-sm text-gray-500">No persisted accounts</div>
          )}
          <DeleteConfirmationDialog
            open={openDeleteConfirmation}
            setOpen={setOpenDeleteConfirmation}
            account={accountToDelete}
          />
        </>
      )}

      {snowflakeAuthType === 'oauth' && (
        <>
          <ul className="w-[22rem] flex flex-col items-center space-y-2">
            {oauthIntegrations?.map((integration: IOauthIntegration, index: number) => (
              <li
                key={integration.account}
                className={classNames(
                  'w-4/5 overflow-hidden rounded-md border cursor-pointer shadow-sm',
                  snowflakeAccount === integration.account ? 'border-gray-400' : 'border-gray-200',
                )}
              >
                <div className="w-full px-2 flex items-center border-b border-gray-900/5 bg-gray-50 py-1">
                  <div className="ml-2 text-gray-700">{integration.account}</div>
                </div>
                <dl className="divide-y divide-gray-100 text-sm max-w-full px-2">
                  <div className="flex gap-x-4 py-1">
                    <dt className="min-w-[5rem] text-gray-500">Username</dt>
                    <dd className="text-gray-700 truncate">{integration.client_id}</dd>
                  </div>
                  <div className="flex gap-x-4 py-1">
                    <dt className="min-w-[5rem] text-gray-500">Role</dt>
                    <dd className="text-gray-700 truncate">{integration.role}</dd>
                  </div>
                  <div className="flex gap-x-4 py-1">
                    <dt className="min-w-[5rem] text-gray-500">Warehouse</dt>
                    <dd className="text-gray-700 truncate">{integration.warehouse}</dd>
                  </div>
                  <div className="flex gap-x-4 py-1">
                    <AccountFormButton
                      label="Create account"
                      onClick={() => {
                        setOpenAuthenticateDialog(true);
                        setIntegrationIndex(index);
                      }}
                    />
                  </div>
                </dl>
              </li>
            ))}
          </ul>
          {integrationIndex >= 0 && (
            <SnowflakeOauthAccountDialog
              integration={oauthIntegrations[integrationIndex]}
              dialogOpen={openAuthenticateDialog}
              setDialogOpen={setOpenAuthenticateDialog}
            />
          )}
        </>
      )}
    </>
  );
}
