import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectNativeAppName,
  selectSpcsDescription,
  setNativeAppName,
  setSpcsDescription,
} from '../../native-app-builder/reducers/nativeAppSlice';
import StepWrapper from 'components/StepWrapper';
import InfoSection from 'components/InfoSection';

export interface SpcsConfigurationStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SpcsConfigurationStep(props: SpcsConfigurationStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const nativeAppName = useAppSelector(selectNativeAppName);
  const spcsDescription = useAppSelector(selectSpcsDescription);

  return (
    <StepWrapper
      title="SPCS configuration"
      subtitle="Provide the SPCS configuration for the SSC Solution"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="h-full">
        <form className="w-full pb-2">
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-4">
            {/* Compute pool configuration */}
            <div className="relative col-span-2 mt-2">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">Compute pool configuration</span>
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={nativeAppName}
                    onChange={(e) => dispatch(setNativeAppName(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder=""
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <form className="w-full pb-6 mt-4">
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-6">
            <div className="col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2 mb-4">
                <textarea
                  id="decription"
                  name="description"
                  value={spcsDescription}
                  onChange={(e) => dispatch(setSpcsDescription(e.target.value))}
                  rows={5}
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  required
                />
              </div>
              <InfoSection
                text="Give a description of what you want to achieve with this SSC Solution component. DataOps.live Assist will
                then automatically generate code to getting you started in the right direction"
              />
            </div>
          </div>
        </form>
      </div>
    </StepWrapper>
  );
}
