import React, { useState } from 'react';
import { useDataAppCreateModulesMutation } from 'app/createApi';
import { useAppSelector } from 'app/hooks';
import {
  selectDeploymentOption,
  selectSelectedModules,
  selectPythonUdfDescription,
  selectSqlUdfDescription,
  selectStreamlitDeploymentOption,
  selectStreamlitDescription,
  selectSpcsDescription,
  selectSnowflakeAiDescription,
  selectCortexDescription,
  selectCortexSearchDescription,
  selectTestSnowflakeAccount,
} from '../../native-app-builder/reducers/nativeAppSlice';
import { selectSnowflakeAccount, selectSelectedDatabase } from 'app/snowflakeSlice';
import { selectSelectedGroup, selectNewProjectName } from 'app/sharedSlice';
import { selectSelectedSchemas } from 'features/data-product-builder/reducers/builderSlice';
import StepWrapper from 'components/StepWrapper';
import OverviewBlock from 'components/OverviewBlock';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import { IModuleType } from 'utils/types';

export interface NativeAppSummaryStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function DataAppSummaryStep(props: NativeAppSummaryStepProps): JSX.Element {
  const deploymentOption = useAppSelector(selectDeploymentOption);
  const selectedModules = useAppSelector(selectSelectedModules);

  const pythonUdfDescription = useAppSelector(selectPythonUdfDescription);
  const sqlUdfDescription = useAppSelector(selectSqlUdfDescription);
  const streamlitDeploymentOption = useAppSelector(selectStreamlitDeploymentOption);
  const streamlitDescription = useAppSelector(selectStreamlitDescription);

  const spcsDescription = useAppSelector(selectSpcsDescription);

  const snowflakeAiDescription = useAppSelector(selectSnowflakeAiDescription);
  const cortexDescription = useAppSelector(selectCortexDescription);
  const cortexSearchDescription = useAppSelector(selectCortexSearchDescription);

  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const testSnowflakeAccount = useAppSelector(selectTestSnowflakeAccount);
  const selectedDatabase = useAppSelector(selectSelectedDatabase);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const group = useAppSelector(selectSelectedGroup);
  const newProjectName = useAppSelector(selectNewProjectName);

  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage] = useState<string>('Failed to setup project');

  const [dataAppCreateModules] = useDataAppCreateModulesMutation();

  const processedModules = selectedModules.map((module) => {
    let moduleType;
    switch (module.type) {
      case 'Python UDF':
        moduleType = 'snowpark';
        break;
      case 'Streamlit':
        moduleType = 'streamlit';
        break;
      case 'SQL UDF':
        moduleType = 'sql';
        break;
      case 'Snowpark Container Services':
        moduleType = 'spcs';
        break;
      case 'Snowflake AI':
        moduleType = 'snowflake-ai';
        break;
      case 'Cortex AI':
        moduleType = 'cortex-ai';
        break;
      case 'Cortex AI Search':
        moduleType = 'cortex-ai-search';
        break;
      case 'Shared Data':
        moduleType = 'data-share';
        break;
      case 'Notebook':
        moduleType = 'notebook';
        break;
      default:
        moduleType = 'unknown';
    }

    const payload: any = {
      module_type: moduleType,
      name: module.name,
      description: '',
      deployment_type: streamlitDeploymentOption === 'sis' ? 'streamlit_in_snowflake' : 'streamlit_in_spcs',
      query_warehouse: 'COMPUTE_WH',
    };

    if (module.type === 'Python UDF') {
      payload.description = pythonUdfDescription;
    }

    if (module.type === 'SQL UDF') {
      payload.description = sqlUdfDescription;
    }

    if (module.type === 'Streamlit') {
      payload.description = streamlitDescription;
    }

    if (module.type === 'Snowpark Container Services') {
      payload.description = spcsDescription;
      payload.role = 'ACCOUNTADMIN';
      payload.min_instances = 1;
      payload.max_instances = 2;
    }

    if (module.type === 'Snowflake AI') {
      payload.description = snowflakeAiDescription;
    }

    if (module.type === 'Cortex AI') {
      payload.description = cortexDescription;
    }

    if (module.type === 'Cortex AI Search') {
      payload.description = cortexSearchDescription;
    }

    if (module.type === 'Shared Data') {
      payload.description = 'Shared data module';
      payload.database = selectedDatabase;
      payload.schemas = selectedSchemas.map((schema) => schema.schema);
    }

    return payload;
  });

  const onContinue = (): void => {
    console.log('modules: ', selectedModules);
    console.log(
      'processed modules: ',
      selectedModules.map(({ type, ...rest }) => ({ ...rest, module_type: type })),
    );
    setLoading(true);
    setFailed(false);
    dataAppCreateModules({
      projectName: `${group.path}/${newProjectName}`,
      account: snowflakeAccount,
      modules: processedModules,
      secondaryAccounts: deploymentOption === 'double' ? [testSnowflakeAccount] : [],
    })
      .unwrap()
      .then((res: any) => {
        props.onContinue();
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        // setErrorMessage(err.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLogo = (moduleType: IModuleType): string => {
    switch (moduleType) {
      case 'Python UDF':
        return '/static/python-udf-logo.png';
      case 'Streamlit':
        return '/static/streamlit-logo.png';
      case 'SQL UDF':
        return '/static/sql-udf-logo.png';
      case 'Snowpark Container Services':
        return '/static/spcs-logo.png';
      case 'Snowflake AI':
        return '/static/snowflake-ai-logo.png';
      case 'Cortex AI':
        return '/static/snowflake-cortex-logo.png';
      case 'Cortex AI Search':
        return '/static/snowflake-cortex-logo.png';
      case 'Shared Data':
        return '/static/snowflake-sharing-logo.png';
      case 'Notebook':
        return '/static/notebook-logo.png';
      default:
        return '/static/snowflake-cortex-logo.png';
    }
  };

  return (
    <StepWrapper
      title="Summary"
      subtitle="Review your components"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      continueDisabled={loading}
    >
      <div className="px-6 w-full wide:w-3/4 mx-[auto]">
        <div className="w-full">
          <OverviewBlock dividerText="New project details">
            <dl className="grid grid-cols-2">
              <div className="border-gray-100 px-4 py-3">
                <dt className="text-sm font-semibold text-gray-900">Group</dt>
                <dd className="text-sm text-gray-700">internal/test_group</dd>
              </div>
              <div className="border-gray-100 px-4 py-3">
                <dt className="text-sm font-semibold text-gray-900">Project</dt>
                <dd className="text-sm text-gray-700 truncate">test_data_app</dd>
              </div>
            </dl>
          </OverviewBlock>
        </div>
        <div className="w-full">
          <OverviewBlock dividerText="Components" backgroundColor="bg-gray-50">
            {selectedModules.length === 0 ? (
              <div className="text-sm text-gray-700 text-center">There are no components</div>
            ) : (
              <ul role="list" className="p-4 grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3 wide:grid-cols-4">
                {selectedModules.map((module, moduleIdx) => (
                  <li key={moduleIdx}>
                    <button
                      type="button"
                      className="group bg-white flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="flex min-w-0 flex-1 items-center space-x-3">
                        <span className="block flex-shrink-0">
                          <img
                            className="h-10 w-10 object-contain p-1 rounded-full overflow-visible"
                            src={getLogo(module.type)}
                          />
                        </span>
                        <span className="block min-w-0 flex-1">
                          <span className="block truncate text-sm font-medium text-gray-900">{module.type}</span>
                          <span className="block truncate text-sm font-medium text-gray-500">{module.name}</span>
                        </span>
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </OverviewBlock>
        </div>

        <OverviewBlock dividerText="Snowflake account">
          <div className="px-6 text-sm text-gray-600">
            {deploymentOption === 'none' && <p>No Snowflake accounts have been connected to the project.</p>}
            {deploymentOption === 'local' && <p>No Snowflake accounts have been connected to the project.</p>}
            {deploymentOption === 'single' && <p>A main Snowflake instance has been connected to the project.</p>}
            {deploymentOption === 'double' && (
              <p>A main Snowflake instance and an external test instance have been connected to the project.</p>
            )}
            {(deploymentOption === 'single' || deploymentOption === 'double') && (
              <div className="mt-2">
                <span>Main Snowflake instance:</span>
                <span className="font-semibold text-gray-800 ml-2">{snowflakeAccount}</span>
              </div>
            )}
            {deploymentOption === 'double' && (
              <div className="mt-2">
                <span>Test Snowflake instance:</span>
                <span className="font-semibold text-gray-800 ml-2">dmbhtnr-nu5112</span>
              </div>
            )}
          </div>
        </OverviewBlock>
        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} />
      </div>
    </StepWrapper>
  );
}
