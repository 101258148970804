import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import type { IModule } from 'utils/types';
import type { RootState } from 'app/store';

type DeploymentOption = 'none' | 'local' | 'single' | 'double';
type StreamlitDeploymentOption = 'sis' | 'standalone';

export interface INativeAppState {
  nativeAppName: string;
  isNativeAppNameFromUrl: boolean;
  selectedModules: IModule[];
  deplyomentOption: DeploymentOption;

  testSnowflakeAccount: string;
  testSnowflakeUsername: string;
  testSnowflakeWarehouse: string;
  testSnowflakeRole: string;
  testSnowflakeAccountConnected: boolean;

  // Config which will be probably handled by IModules
  // Python UDF
  pythonUdfDescription: string;

  // SQL UDF
  sqlUdfDescription: string;

  // Streamlit config
  streamlitSchema: string;
  streamlitDeploymentOption: StreamlitDeploymentOption;
  streamlitDescription: string;

  // SPCS config
  spcsRepositoryName: string;
  spcsRepositorySchema: string;
  spcsComputePoolName: string;
  spcsDescription: string;

  // Snowflake AI
  snowflakeAiDescription: string;

  // Cortex AI
  cortexDescription: string;

  // Cortex AI Search
  cortexSearchDescription: string;

  // Package
  packageName: string;
  packageRole: string;
  packageWarehouse: string;
  applicationName: string;
  applicationRole: string;
  applicationWarehouse: string;
}

export const initialState: INativeAppState = {
  nativeAppName: 'My Native App',
  isNativeAppNameFromUrl: false,
  selectedModules: [],
  deplyomentOption: 'single',
  testSnowflakeAccount: '',
  testSnowflakeUsername: '',
  testSnowflakeWarehouse: '',
  testSnowflakeRole: '',
  testSnowflakeAccountConnected: false,
  pythonUdfDescription: '',
  sqlUdfDescription: '',
  streamlitSchema: 'Public',
  streamlitDeploymentOption: 'sis',
  streamlitDescription: '',
  spcsRepositoryName: 'REPOSITORY',
  spcsRepositorySchema: 'REPOSITORY',
  spcsComputePoolName: 'COMPUTE_POOL',
  spcsDescription: '',
  snowflakeAiDescription: '',
  cortexDescription: '',
  cortexSearchDescription: '',
  packageName: 'my_native_app_pkg',
  packageRole: '',
  packageWarehouse: '',
  applicationName: 'my_native_app',
  applicationRole: '',
  applicationWarehouse: '',
};

export const nativeAppSlice = createSlice({
  name: 'nativeApp',
  initialState,
  reducers: {
    setNativeAppName: (state, action: PayloadAction<string>) => {
      state.nativeAppName = action.payload;
      state.packageName = `${action.payload.replaceAll(' ', '_').toLowerCase()}_pkg`;
      state.applicationName = action.payload.replaceAll(' ', '_').toLowerCase();
    },
    setIsNativeAppNameFromUrl: (state, action: PayloadAction<boolean>) => {
      state.isNativeAppNameFromUrl = action.payload;
    },
    setSelectedModules: (state, action: PayloadAction<IModule[]>) => {
      state.selectedModules = action.payload;
    },
    setTestSnowflakeAccount: (state, action: PayloadAction<string>) => {
      state.testSnowflakeAccount = action.payload;
    },
    setTestSnowflakeUsername: (state, action: PayloadAction<string>) => {
      state.testSnowflakeUsername = action.payload;
    },
    setTestSnowflakeWarehouse: (state, action: PayloadAction<string>) => {
      state.testSnowflakeWarehouse = action.payload;
    },
    setTestSnowflakeRole: (state, action: PayloadAction<string>) => {
      state.testSnowflakeRole = action.payload;
    },
    setTestSnowflakeAccountConnected: (state, action: PayloadAction<boolean>) => {
      state.testSnowflakeAccountConnected = action.payload;
    },
    setDeploymentOption: (state, action: PayloadAction<DeploymentOption>) => {
      state.deplyomentOption = action.payload;
    },
    setPythonUdfDescription: (state, action: PayloadAction<string>) => {
      state.pythonUdfDescription = action.payload;
    },
    setSqlUdfDescription: (state, action: PayloadAction<string>) => {
      state.sqlUdfDescription = action.payload;
    },
    setStreamlitSchema: (state, action: PayloadAction<string>) => {
      state.streamlitSchema = action.payload;
    },
    setStreamlitDeploymentOption: (state, action: PayloadAction<StreamlitDeploymentOption>) => {
      state.streamlitDeploymentOption = action.payload;
    },
    setStreamlitDescription: (state, action: PayloadAction<string>) => {
      state.streamlitDescription = action.payload;
    },
    setSpcsRepositoryName: (state, action: PayloadAction<string>) => {
      state.spcsRepositoryName = action.payload;
    },
    setSpcsRepositorySchema: (state, action: PayloadAction<string>) => {
      state.spcsRepositorySchema = action.payload;
    },
    setSpcsComputePoolName: (state, action: PayloadAction<string>) => {
      state.spcsComputePoolName = action.payload;
    },
    setSpcsDescription: (state, action: PayloadAction<string>) => {
      state.spcsDescription = action.payload;
    },
    setSnowflakeAiDescription: (state, action: PayloadAction<string>) => {
      state.snowflakeAiDescription = action.payload;
    },
    setCortexDescription: (state, action: PayloadAction<string>) => {
      state.cortexDescription = action.payload;
    },
    setCortexSearchDescription: (state, action: PayloadAction<string>) => {
      state.cortexSearchDescription = action.payload;
    },
    setPackageName: (state, action: PayloadAction<string>) => {
      state.packageName = action.payload;
    },
    setPackageRole: (state, action: PayloadAction<string>) => {
      state.packageRole = action.payload;
    },
    setPackageWarehouse: (state, action: PayloadAction<string>) => {
      state.packageWarehouse = action.payload;
    },
    setApplicationName: (state, action: PayloadAction<string>) => {
      state.applicationName = action.payload;
    },
    setApplicationRole: (state, action: PayloadAction<string>) => {
      state.applicationRole = action.payload;
    },
    setApplicationWarehouse: (state, action: PayloadAction<string>) => {
      state.applicationWarehouse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  setNativeAppName,
  setIsNativeAppNameFromUrl,
  setSelectedModules,
  setDeploymentOption,
  setTestSnowflakeAccount,
  setTestSnowflakeUsername,
  setTestSnowflakeWarehouse,
  setTestSnowflakeRole,
  setTestSnowflakeAccountConnected,
  setPythonUdfDescription,
  setSqlUdfDescription,
  setStreamlitSchema,
  setStreamlitDeploymentOption,
  setStreamlitDescription,
  setSpcsRepositoryName,
  setSpcsRepositorySchema,
  setSpcsComputePoolName,
  setSpcsDescription,
  setSnowflakeAiDescription,
  setCortexDescription,
  setCortexSearchDescription,
  setPackageName,
  setPackageRole,
  setPackageWarehouse,
  setApplicationName,
  setApplicationRole,
  setApplicationWarehouse,
} = nativeAppSlice.actions;

export const selectNativeAppName = (state: RootState): string => state.nativeApp.nativeAppName;
export const selectIsNativeAppNameFromUrl = (state: RootState): boolean => state.nativeApp.isNativeAppNameFromUrl;
export const selectSelectedModules = (state: RootState): IModule[] => state.nativeApp.selectedModules;
export const selectDeploymentOption = (state: RootState): DeploymentOption => state.nativeApp.deplyomentOption;
export const selectTestSnowflakeAccount = (state: RootState): string => state.nativeApp.testSnowflakeAccount;
export const selectTestSnowflakeUsername = (state: RootState): string => state.nativeApp.testSnowflakeUsername;
export const selectTestSnowflakeWarehouse = (state: RootState): string => state.nativeApp.testSnowflakeWarehouse;
export const selectTestSnowflakeRole = (state: RootState): string => state.nativeApp.testSnowflakeRole;
export const selectTestSnowflakeAccountConnected = (state: RootState): boolean =>
  state.nativeApp.testSnowflakeAccountConnected;
export const selectPythonUdfDescription = (state: RootState): string => state.nativeApp.pythonUdfDescription;
export const selectSqlUdfDescription = (state: RootState): string => state.nativeApp.sqlUdfDescription;
export const selectStreamlitSchema = (state: RootState): string => state.nativeApp.streamlitSchema;
export const selectStreamlitDeploymentOption = (state: RootState): StreamlitDeploymentOption =>
  state.nativeApp.streamlitDeploymentOption;
export const selectStreamlitDescription = (state: RootState): string => state.nativeApp.streamlitDescription;
export const selectSpcsRepositoryName = (state: RootState): string => state.nativeApp.spcsRepositoryName;
export const selectSpcsRepositorySchema = (state: RootState): string => state.nativeApp.spcsRepositorySchema;
export const selectSpcsComputePoolName = (state: RootState): string => state.nativeApp.spcsComputePoolName;
export const selectSpcsDescription = (state: RootState): string => state.nativeApp.spcsDescription;
export const selectSnowflakeAiDescription = (state: RootState): string => state.nativeApp.snowflakeAiDescription;
export const selectCortexDescription = (state: RootState): string => state.nativeApp.cortexDescription;
export const selectCortexSearchDescription = (state: RootState): string => state.nativeApp.cortexSearchDescription;
export const selectPackageName = (state: RootState): string => state.nativeApp.packageName;
export const selectPackageRole = (state: RootState): string => state.nativeApp.packageRole;
export const selectPackageWarehouse = (state: RootState): string => state.nativeApp.packageWarehouse;
export const selectApplicationName = (state: RootState): string => state.nativeApp.applicationName;
export const selectApplicationRole = (state: RootState): string => state.nativeApp.applicationRole;
export const selectApplicationWarehouse = (state: RootState): string => state.nativeApp.applicationWarehouse;

export default nativeAppSlice.reducer;
